import * as Ably from 'ably';

import { config } from '../../config';

const { TRANSIT_WATCH_ABLY_TOKEN } = config;

const transitWatchOptions: Ably.Types.ClientOptions = {
  key: TRANSIT_WATCH_ABLY_TOKEN,
};

// Ably client for Transit Watch Service
export const transitWatchClient = new Ably.Realtime(transitWatchOptions);

export const startTransitWatchSocketIO = () => {
  try {
    transitWatchClient.connect();

    transitWatchClient.connection.on('connected', () => {
      console.info(
        '---------------- TRANSIT WATCH Socket connected -----------------------',
      );
    });

    transitWatchClient.connection.on('failed', () => {
      console.info(
        '---------------- TRANSIT WATCH Socket failed, retrying manually ----------',
      );

      transitWatchClient.connect();
    });

    transitWatchClient.connection.on('disconnected', () => {
      console.info(
        '---------------- TRANSIT WATCH Socket connection lost ----------',
      );
      transitWatchClient.connect();
    });

    return transitWatchClient;
  } catch (error) {
    console.error({ error });
    // Update status
    return null;
  }
};
