import { handleActions } from 'redux-actions';

import {
  CLEAR_API_ERROR,
  RESET_STATE,
  SAVE_DISCOUNT,
  SET_API_ERROR,
  SET_LOADING,
  SET_DISCOUNT_TO_UPDATE,
} from './action-types';

import { IDiscountDefaultState } from './types';

export const defaultState: IDiscountDefaultState = {
  loading: false,

  apiError: undefined,

  discounts: [],

  discountToUpdate: undefined,
};

// @ts-ignore
export default handleActions(
  {
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false,
    }),
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_DISCOUNT]: (state, { payload }) => ({
      ...state,
      discounts: payload,
    }),
    [SET_DISCOUNT_TO_UPDATE]: (state, { payload }) => ({
      ...state,
      discountToUpdate: payload,
    }),
    [RESET_STATE]: (state) => ({
      ...state,
      ...defaultState,
    }),
  },
  defaultState,
);
