import i18n, { Resource } from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { ENGLISH } from '../../../constants';
// locales
import en from '../../../locales/en.json';
import sw from '../../../locales/sw.json';

import { IState } from '../../../types/state';

import { config } from '../../../config';

export interface II18nConfig {
  resources: Resource;
  ns: string;
  lng: string;
  fallbackLng?: string;
}

const defaultTranslations: Resource = {
  en: {
    translation: en,
  },
  sw: {
    translation: sw,
  },
};

const i18nConfig: II18nConfig = {
  resources: defaultTranslations,
  ns: 'translation',
  lng: ENGLISH,
  fallbackLng: ENGLISH,
};

export interface TranslatedPair {
  [key: string]: string;
}

export const useSafiriTranslations = (ns = undefined) => {
  const { t: translation, i18n } = useTranslation(ns);

  const language = useSelector(({ global: { language } }: IState) => language);

  useEffect(() => {
    i18n.changeLanguage(language?.languageTag || '');
    moment.locale(language?.languageTag);
  }, [i18n, language]);

  return { i18n, translation };
};

export const SetI18nConfig = ({
  resources,
  ns,
  fallbackLng,
  lng,
}: II18nConfig) => {
  // setup translation instance
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng,
      fallbackLng,
      debug: config.REACT_APP_DEV_MODE,
      interpolation: {
        escapeValue: false,
        format: function (value, format) {
          if (format === 'currency') return numeral(value).format('0,0');

          return value;
        },
      },
      resources,
      ns,
      defaultNS: 'translation',
    });
};

SetI18nConfig(i18nConfig);

export default i18n;
