export type ICoreUIState = {
  sidebarShow: 'responsive' | boolean;
  asideShow: boolean;
  darkMode: boolean;
};

export const initialState: ICoreUIState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
};

type args = { type?: string; [key: string]: any };

const changeState = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

// const store = createStore(changeState)
export default changeState;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
// export const useTypedSelector: TypedUseSelectorHook<ICoreUIState> = useSelector;
