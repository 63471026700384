import { COMPANY_TYPE, groupedCompanyCategory } from '../constants/company';

export const findCompanyCategory = (companyType: keyof typeof COMPANY_TYPE) => {
  let category = COMPANY_TYPE.UPCOUNTRY;

  // Find the category that the company falls under
  Object.values(groupedCompanyCategory).forEach((categories, index) => {
    const isFound = categories.find(
      (categoryValue) => categoryValue === companyType,
    );

    if (isFound)
      category = Object.keys(groupedCompanyCategory)[index] as COMPANY_TYPE;
  });

  return category;
};
