import { handleActions } from 'redux-actions';

import {
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOADING,
  SAVE_BUSINESS,
  SAVE_OFFICES,
  SAVE_STAFF,
} from './action-types';
import { IBusinessDefaultState } from './types';

export const defaultState: IBusinessDefaultState = {
  apiError: undefined,
  loading: false,
  details: undefined,
  offices: [],
  staff: [],
};

// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false, // set loading to false after setting the API error
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_BUSINESS]: (state, { payload }) => ({
      ...state,
      details: payload,
      offices: payload.offices,
    }),
    [SAVE_OFFICES]: (state, { payload }) => ({
      ...state,
      offices: payload,
    }),
    [SAVE_STAFF]: (state, { payload }) => ({
      ...state,
      staff: payload,
    }),
  },
  defaultState,
);
