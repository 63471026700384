import { handleActions } from 'redux-actions';

import {
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOADING,
  SAVE_APPLICATIONS,
  SAVE_CURRENT_APPLICATION,
} from './action-types';
import { IApplicationsDefaultState } from './types';

export const defaultState: IApplicationsDefaultState = {
  apiError: undefined,
  loading: false,

  details: [],
  currentApplication: undefined,
};

// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false, // set loading to false after setting the API error
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_APPLICATIONS]: (state, { payload }) => ({
      ...state,
      details: payload,
    }),
    [SAVE_CURRENT_APPLICATION]: (state, { payload }) => ({
      ...state,
      currentApplication: payload,
    }),
  },
  defaultState,
);
