export const SET_API_ERROR = 'company/SET_API_ERROR';
export const CLEAR_API_ERROR = 'company/CLEAR_API_ERROR';

export const START_COMPANY_UPDATE = 'company/START_COMPANY_UPDATE';
export const SAVE_COMPANY = 'company/SAVE_COMPANY';
export const SAVE_COMPANIES = 'company/SAVE_COMPANIES';
export const SAVE_UPDATED_COMPANY = 'company/SAVE_UPDATED_COMPANY';
export const END_COMPANY_UPDATE = 'company/END_COMPANY_UPDATE';

export const RESET_STATE = 'company/RESET_STATE';
export const RESET_LOADING = 'company/RESET_LOADING';

export const SAVE_STAFF = 'company/SAVE_STAFF';

export const SAVE_TRIPS = 'company/SAVE_TRIPS';

export const SAVE_MADE_TRIPS = 'company/SAVE_MADE';

export const SAVE_TICKETS_SOLD = 'company/SAVE_TICKETS_SOLD';

export const SAVE_PERIODICAL_SALES = 'company/SAVE_PERIODICAL_SALES';

export const SAVE_PERIODICAL_PARCEL_DELIVERY_SALES =
  'company/SAVE_PERIODICAL_PARCEL_DELIVERY_SALES';

export const SAVE_SENT_PARCELS = 'company/SAVE_SENT_PARCELS';

export const SAVE_DELIVERED_PARCELS = 'company/SAVE_DELIVERED_PARCELS';

export const SAVE_EXPENSES = 'company/SAVE_EXPENSES';

export const SAVE_LEASED_VEHICLES = 'company/SAVE_LEASED_VEHICLES';

export const SAVE_DASHBOARD_SUMMARIES = 'company/SAVE_DASHBOARD_SUMMARIES';

export const SAVE_DASHBOARD_INCOME_SUMMARIES =
  'company/SAVE_DASHBOARD_INCOME_SUMMARIES';

export const SAVE_WEBSITE_CONFIG = 'company/SAVE_WEBSITE_CONFIG';
