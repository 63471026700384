export const SET_API_ERROR = 'vehicle/SET_API_ERROR';
export const CLEAR_API_ERROR = 'vehicle/CLEAR_API_ERROR';

export const SET_SEAT_GRID_TEMPLATE = 'vehicle/FETCH_SEATING_GRIDS';
export const START_VEHICLE_UPDATE = 'vehicle/START_VEHICLE_UPDATE';
export const SAVE_VEHICLE = 'vehicle/SAVE_VEHICLE';
export const UPDATE_VEHICLE = 'vehicle/UPDATE_VEHICLE';
export const DELETE_VEHICLE = 'vehicle/DELETE_VEHICLE';
export const END_VEHICLE_UPDATE = 'vehicle/END_VEHICLE_UPDATE';

export const SET_SELECTED_VEHICLE = 'vehicle/SET_SELECTED_VEHICLE';

export const RESET_STATE = 'vehicle/RESET_STATE';
export const SET_LOADING = 'vehicle/SET_LOADING';
