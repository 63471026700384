export const SET_API_ERROR = 'logon/SET_API_ERROR';
export const CLEAR_API_ERROR = 'logon/CLEAR_API_ERROR';
export const START_ATTEMPT_LOGON = 'logon/START_ATTEMPT_LOGON';
export const END_ATTEMPT_LOGON = 'logon/END_ATTEMPT_LOGON';
export const SAVE_PHONE_NUMBER = 'logon/SAVE_PHONE_NUMBER';

export const SET_LOGGED_IN = 'logon/SET_LOGGED_IN';
export const SET_REDIRECT = 'logon/SET_REDIRECT';

export const LOGON = 'logon/LOGON';
export const LOGON_COMMIT = 'logon/LOGON_COMMIT';
export const LOGON_ROLLBACK = 'logon/LOGON_ROLLBACK';

export const SET_IS_SAFIRI_AGENT = 'logon/SET_IS_SAFIRI_AGENT';
