import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import requestRetry, { ResponseError } from '../../lib/request';

import * as action from './action-types';
import * as routes from '../../constants/routes';

import { config } from '../../config';
import { IBusiness } from '../../types/business';
import { IResponseError } from '../../types/errors';
import { IUser } from '../../types/user';
import { IOffice, IOfficeFormInput } from '../../types/office';

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const setApiError = createAction(action.SET_API_ERROR);
export const clearApiError = createAction(action.CLEAR_API_ERROR);

export const setLoading = createAction(action.SET_LOADING);

export const saveBusiness = createAction(action.SAVE_BUSINESS);
export const saveOffices = createAction(action.SAVE_OFFICES);
export const saveStaff = createAction(action.SAVE_STAFF);

/**
 * @function getBusiness
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET to get a business using the uuid provided
 */
export const getBusiness =
  (uuid: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IBusiness | ResponseError> => {
    try {
      // dispatch action that sets "loading: true"
      dispatch(setLoading(true));

      // Search for applications
      const response = await requestFn<IBusiness>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_BUSINESS}/${uuid}`,
      );

      // Dispatch Redux Action to save the business to Redux Store
      dispatch(saveBusiness(response));

      // Remove loading
      dispatch(setLoading(false));

      // return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError());
      dispatch(setLoading(false));

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET to get all staff for a business using the uuid provided
 * @function getAllBusinessStaff
 * @param uuid
 * @param requestFn
 */
export const getAllBusinessStaff =
  (uuid: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser[]> => {
    try {
      // dispatch an action to clear api errors
      dispatch(clearApiError());

      // dispatch action that sets "loading: true"
      dispatch(setLoading(true));

      // find all staff
      const allStaff = await requestFn<IUser[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_STAFF}?uuid=${uuid}`,
      );

      // Dispatch Redux Action to save all staff to Redux Store
      dispatch(saveStaff(allStaff));

      // Remove loading
      dispatch(setLoading(false));

      return allStaff;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST to add a new office
 *
 *
 * @param {IOfficeFormInput} office office details to be added to the company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 * @return {IResponseError | ICompany}
 */
export const addBusinessOffice =
  (
    office: IOfficeFormInput,
    {
      uuid,
    }: {
      uuid: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IBusiness> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // dispatch action that sets "loading: true"
      dispatch(setLoading(true));

      // Add office
      const response = await requestFn<IBusiness>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_BUSINESS}/${uuid}${routes.OFFICES}`,
        {
          method: 'POST',
          // Fetch API wants you to stringify this object
          body: JSON.stringify(office),
        },
      );

      // dispatch an action to save the business
      dispatch(saveBusiness(response));

      // Remove loading
      dispatch(setLoading(false));

      // return results of the request
      return response;
    } catch (error) {
      // set api Error if any
      dispatch(setApiError(error));

      // Remove loading
      dispatch(setLoading(false));

      // return the error from the API
      return error as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT and updates this office from the company offices[]
 *
 *
 * @param {IOffice} office office details to be removed from company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 */
export const updateBusinessOffice =
  (
    office: IOffice,
    {
      uuid,
    }: {
      uuid: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IBusiness> => {
    try {
      // clear api error if any
      dispatch(clearApiError());

      // dispatch action that sets "loading: true"
      dispatch(setLoading(true));

      // send an HTTP request to update an office
      const response = await requestFn<IBusiness>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_BUSINESS}/${uuid}${routes.OFFICES}`,
        {
          method: 'PUT',
          body: JSON.stringify(office),
        },
      );

      // update the business offices
      dispatch(saveBusiness(response));

      // Remove loading
      dispatch(setLoading(false));

      // return results of the request
      return response;
    } catch (e) {
      // set api Error if any
      dispatch(setApiError(e));

      // Remove loading
      dispatch(setLoading(false));

      // return the error from the API
      return e as ResponseError;
    }
  };

/**
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE and remove this office from the company
 *
 *
 * @param {IOffice} office office details to be removed from company
 * @param param0 {companyId: string}
 * @param {Function} requestFn - Request function (Optional)
 */
export const deleteBusinessOffice =
  (
    office: IOffice,
    {
      uuid,
    }: {
      uuid: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IBusiness> => {
    try {
      // clear apiErrors
      dispatch(clearApiError());

      // dispatch action to set loading
      dispatch(setLoading(true));

      // Remove office on the backend
      const response = await requestFn<IBusiness>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_BUSINESS}/${uuid}${routes.OFFICES}/${office.id}`,
        {
          method: 'DELETE',
        },
      );

      // update the business offices
      dispatch(saveBusiness(response));

      // Remove loading
      dispatch(setLoading(false));

      // return results of the request
      return response;
    } catch (e) {
      // set api Error if any
      dispatch(setApiError(e));

      // Remove loading
      dispatch(setLoading(false));

      // return the error from the API
      return e as ResponseError;
    }
  };
