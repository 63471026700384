import { handleActions } from 'redux-actions';
import {
  CLEAR_API_ERROR,
  RESET_PLAN_STATE,
  SAVE_PLAN,
  SET_API_ERROR,
  SET_LOADING,
} from './action-types';
import { IServicePlanDefaultState } from './types';

export const defaultState: IServicePlanDefaultState = {
  loading: false,
  servicePlans: [],
  apiError: undefined,
};

//@ts-ignore
export default handleActions(
  {
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
    }),
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_PLAN]: (state, { payload }) => ({
      ...state,
      servicePlans: payload,
    }),
    [RESET_PLAN_STATE]: (state) => ({
      ...state,
      ...defaultState,
    }),
  },
  defaultState,
);
