import { IDevicePayload, IRealtimeDefaultState } from './types';
import { Action, handleActions } from 'redux-actions';
import { SAVE_DEVICE_MESSAGE, SAVE_DEVICE_MESSAGES } from './action-types';

export const defaultState: IRealtimeDefaultState = {
  messages: [],
  loading: false,
  apiError: undefined,
};

export default handleActions(
  {
    [SAVE_DEVICE_MESSAGES]: (state, action: Action<IDevicePayload[]>) => ({
      ...state,
      messages: action.payload,
    }),
    [SAVE_DEVICE_MESSAGE]: (state, { payload }: any) => ({
      ...state,
      messages: [...state.messages, payload],
    }),
  },
  defaultState,
);
