import { TranslatedPair } from './translations';
import { FormErrors } from '../../../constants';

// the formErrors is initialized in App.tsx
export const formTranslations: { errors?: FormErrors } = {};

// vehicle properties translations
export const vehicleProperties: { property?: TranslatedPair } = {};

// route name translations
export const routeNames: { nameText?: TranslatedPair } = {};

// user roles translations
export const userRolesTranslations: { role?: TranslatedPair } = {};

// company type translations
export const companyType: { typeText?: TranslatedPair } = {};
