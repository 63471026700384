import { handleActions } from 'redux-actions';
import {
  RHF_SAVE_LOGON_DETAILS,
  RHF_CLEAR_LOGON_DETAILS,
} from './action-types';
import { IRHFDefaultState } from './types';

export const defaultState: IRHFDefaultState = {
  logonDetails: undefined,
};

export default handleActions(
  {
    // @ts-ignore
    [RHF_SAVE_LOGON_DETAILS]: (state, { payload }) => ({
      ...state,
      logonDetails: payload,
    }),
    [RHF_CLEAR_LOGON_DETAILS]: (state) => ({
      ...state,
      logonDetails: undefined,
    }),
  },
  defaultState,
);
