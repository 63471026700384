// company entity constants

import { ISafiriAPiErrorMap } from './user';
import { TranslatedPair } from '../lib/hooks/translation/translations';
import { config } from '../config';

export const DALADALA = 'DALADALA';
export const UPCOUNTRY = 'UPCOUNTRY';
export const MARINE = 'MARINE';
export const AIR = 'AIR';
export const TRAIN = 'TRAIN';
export const SGR_TRAIN = 'SGR_TRAIN';
export const MGR_TRAIN = 'MGR_TRAIN';
export const TAXI = 'TAXI';
export const BRT = 'BRT';
export const LORRY = 'LORRY';

export const SAFIRI_API_ERROR_MAP: ISafiriAPiErrorMap = {
  fullName: 'adminName',
  phoneNumber: 'adminPhone',
  countryCode: 'adminPhone',
  password: 'adminPassword',
  email: 'adminEmail',
};

export const SAFIRI_CLIENT_API_ERROR_MAP: ISafiriAPiErrorMap = {
  phoneNumber: 'companyPhone',
  countryCode: 'companyPhone',
  countryCodeName: 'companyPhone',
  accountAddress: 'address',
};

export enum COMPANY_TYPE {
  PLACEHOLDER = 'PLACEHOLDER',
  DALADALA = 'DALADALA',
  UPCOUNTRY = 'UPCOUNTRY',
  MARINE = 'MARINE',
  AIR = 'AIR',
  TRAIN = 'TRAIN',
  SGR_TRAIN = 'SGR_TRAIN',
  MGR_TRAIN = 'MGR_TRAIN',
  TAXI = 'TAXI',
  BRT = 'BRT',
  CARGO = 'CARGO',
  UPCOUNTRY_CARGO = 'UPCOUNTRY_CARGO',
  VEHICLE_LEASE = 'VEHICLE_LEASE',
}

export const createCompanyType = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [COMPANY_TYPE.PLACEHOLDER]: translation('register.company.type.select'),
    [COMPANY_TYPE.UPCOUNTRY]: translation('register.company.type.upcountry'),
    [COMPANY_TYPE.DALADALA]: translation('register.company.type.daladala'),
    [COMPANY_TYPE.TAXI]: translation('register.company.type.taxi'),
    [COMPANY_TYPE.BRT]: translation('register.company.type.brt'),
    [COMPANY_TYPE.TRAIN]: translation('register.company.type.train'),
    [COMPANY_TYPE.MARINE]: translation('register.company.type.marine'),
    [COMPANY_TYPE.AIR]: translation('register.company.type.air'),
    [COMPANY_TYPE.SGR_TRAIN]: translation('register.company.type.sgr'),
    [COMPANY_TYPE.MGR_TRAIN]: translation('register.company.type.mgr'),
    [COMPANY_TYPE.CARGO]: translation('register.company.type.cargo'),
    [COMPANY_TYPE.UPCOUNTRY_CARGO]: translation(
      'register.company.type.upcountryCargo',
    ),
    [COMPANY_TYPE.VEHICLE_LEASE]: translation('register.company.type.lease'),
  };
};

export const agentPayment = {
  PLACEHOLDER: 'PLACEHOLDER',
  PAY_AS_EMPLOYEE: 'PAY_AS_EMPLOYEE',
  PAY_BY_TRIP: 'PAY_BY_TRIP',
  PAY_BY_ROUTE: 'PAY_BY_ROUTE',
  PAY_BY_USER: 'PAY_BY_USER',
};

export const createAgentPayment = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [agentPayment.PLACEHOLDER]: translation(
      'register.company.agentPayment.select',
    ),
    [agentPayment.PAY_AS_EMPLOYEE]: translation(
      'register.company.agentPayment.payAsEmployee',
    ),
    [agentPayment.PAY_BY_TRIP]: translation(
      'register.company.agentPayment.payByTrip',
    ),
    [agentPayment.PAY_BY_ROUTE]: translation(
      'register.company.agentPayment.payByRoute',
    ),
    [agentPayment.PAY_BY_USER]: translation(
      'register.company.agentPayment.payByUser',
    ),
  };
};

export enum CHARGE_TYPE {
  COMPANY = 'Company',
  AGENT = 'Agent',
}

export const createCompanyChargeType = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [CHARGE_TYPE.COMPANY]: translation('register.company.chargeType.company'),
    [CHARGE_TYPE.AGENT]: translation('register.company.chargeType.agent'),
  };
};

export enum PAYMENT_METHOD {
  INVOICE = 'Invoice',
  FLOAT = 'Float',
  SUBSCRIPTION = 'Subscription',
}

export const createCompanyPaymentMethod = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [PAYMENT_METHOD.INVOICE]: translation(
      'register.company.paymentMethod.invoice',
    ),
    [PAYMENT_METHOD.FLOAT]: translation('register.company.paymentMethod.float'),
    [PAYMENT_METHOD.SUBSCRIPTION]: translation(
      'register.company.paymentMethod.subscription',
    ),
  };
};

export enum COMPANY_CATEGORY {
  PARCELS_ONLY = 'PARCELS_ONLY',
  PARCEL_TICKETS = 'PARCEL_TICKETS',
  TICKETS_ONLY = 'TICKETS_ONLY',
}

export enum COMPANY_LOGO_NAME {
  MAIN = 'MAIN',
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  TICKET = 'TICKET',
}

export const groupedCompanyCategory = {
  PARCELS_ONLY: [COMPANY_TYPE.AIR, COMPANY_TYPE.CARGO],
  PARCEL_TICKETS: [
    COMPANY_TYPE.SGR_TRAIN,
    COMPANY_TYPE.MGR_TRAIN,
    COMPANY_TYPE.TRAIN,
    COMPANY_TYPE.BRT,
    COMPANY_TYPE.UPCOUNTRY_CARGO,
    COMPANY_TYPE.DALADALA,
  ],
  TICKETS_ONLY: [COMPANY_TYPE.UPCOUNTRY, COMPANY_TYPE.DALADALA],
};

export type IGTFS_API = {
  [key in COMPANY_TYPE]: string | undefined;
};

export type TCompanyType = Omit<IGTFS_API, COMPANY_TYPE.PLACEHOLDER>;

export const GTFS_API: Omit<IGTFS_API, COMPANY_TYPE.PLACEHOLDER> = {
  [COMPANY_TYPE.DALADALA]: config.REACT_APP_GTFS_DALADALA_API_URL,
  [COMPANY_TYPE.UPCOUNTRY]: config.REACT_APP_GTFS_API_URL,
  [COMPANY_TYPE.UPCOUNTRY_CARGO]: config.REACT_APP_GTFS_API_URL,
  [COMPANY_TYPE.MARINE]: config.REACT_APP_GTFS_MARINE_API_URL,
  [COMPANY_TYPE.AIR]: config.REACT_APP_GTFS_AIR_API_URL,
  [COMPANY_TYPE.TRAIN]: config.REACT_APP_GTFS_TRAIN_API_URL,
  [COMPANY_TYPE.SGR_TRAIN]: config.REACT_APP_GTFS_SGR_API_URL,
  [COMPANY_TYPE.MGR_TRAIN]: config.REACT_APP_GTFS_MGR_API_URL,
  [COMPANY_TYPE.TAXI]: config.REACT_APP_GTFS_TAXI_API_URL,
  [COMPANY_TYPE.BRT]: config.REACT_APP_GTFS_BRT_URL,
  [COMPANY_TYPE.CARGO]: config.REACT_APP_GTFS_CARGO_API_URL,
  [COMPANY_TYPE.VEHICLE_LEASE]: config.REACT_APP_GTFS_API_URL,
};

export enum TICKET_NUMBER_TYPE {
  /* 
  Format: CCCYYYYMMDD#####
  CCC - City code (e.g., LSK for Lusaka)
  YYYY - Year
  MM - Month
  DD - Date
  ##### - Sequential number
  Example: LSK2024061400001
  */
  CITY_BASED_SEQUENTIAL = 'CITY_BASED_SEQUENTIAL',

  RANDOM_NUMERIC = 'RANDOM_NUMERIC',
  RANDOM_ALPHA_NUMERIC = 'RANDOM_ALPHA_NUMERIC',

  /* 
  Format: CCYYYYMMDDXXX###
CC - Country code (e.g., TZ for Tanzania)
YYYY - Year
MM - Month
DD - Date
XXX - Random alphanumeric characters
### - Sequential number
Example: TZ20240614ABC001 */
  ALPHA_NUMERIC_SEQUENTIAL = 'ALPHA_NUMERIC_SEQUENTIAL',

  /*
  Format: CCCCYYYYMM#####
CCCC - Company code (e.g., DRT for Dar Transit)
YYYY - Year
MM - Month
##### - Sequential number
Example: DRT20240600001
  */
  COMPANY_BASED_SEQUENTIAL = 'COMPANY_BASED_SEQUENTIAL',

  /*
  Format: RCCCYYMMDD###
R - Route code (e.g., R for Route 1)
YYYY - Year
MM - Month
DD - Date
##### - Sequential number
Example: RLSK240614001
  */
  ROUTE_BASED_SEQUENTIAL = 'ROUTE_BASED_SEQUENTIAL',

  /*
  Format: YYYYMMDDHHMM####
YYYY - Year
MM - Month
DD - Date
HHMM - Hour and minute
#### - Sequential number
Example: 2024061412300001
  */
  TIME_STAMPED = 'TIME_STAMPED',

  /*
  Format: CATYYYYMMDD###
CAT - Category code (e.g., BUS for bus, FLT for flight)
YYYY - Year
MM - Month
DD - Date
### - Sequential number
Example: BUS20240614001
  */
  CATEGORY_BASED = 'CATEGORY_BASED',

  /*
  // TODO: stop codes on GTFS
Format: CCCTYYYMMDDXXX####
CCC - City code
T - Ticket type (e.g., B for bus, F for flight)
YYY - Year (last three digits)
MM - Month
DD - Date
XXX - Random alphanumeric characters
#### - Sequential number
Example: LSKB0240614XYZ001
*/
  HYBRID = 'HYBRID',

  /*
Format: YYYYMMDD-RANDOM
YYYY - Year
MM - Month
DD - Date
RANDOM - Random unique alphanumeric string
Example: 20240614-A3F5Z7
*/
  DATE_BASED_RANDOM_ALPHA_NUMERIC = 'DATE_BASED_RANDOM_ALPHA_NUMERIC',
}

export const createTicketNumberingScheme = (
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [TICKET_NUMBER_TYPE.ALPHA_NUMERIC_SEQUENTIAL]: translation(
      'register.company.ticketNumberingScheme.schemes.alphaNumericSequential',
    ),
    [TICKET_NUMBER_TYPE.CATEGORY_BASED]: translation(
      'register.company.ticketNumberingScheme.schemes.categoryBased',
    ),
    [TICKET_NUMBER_TYPE.CITY_BASED_SEQUENTIAL]: translation(
      'register.company.ticketNumberingScheme.schemes.cityBasedSequential',
    ),
    [TICKET_NUMBER_TYPE.COMPANY_BASED_SEQUENTIAL]: translation(
      'register.company.ticketNumberingScheme.schemes.companyBasedSequentiail',
    ),
    [TICKET_NUMBER_TYPE.DATE_BASED_RANDOM_ALPHA_NUMERIC]: translation(
      'register.company.ticketNumberingScheme.schemes.dateBasedRandomAlphanumeric',
    ),
    [TICKET_NUMBER_TYPE.ROUTE_BASED_SEQUENTIAL]: translation(
      'register.company.ticketNumberingScheme.schemes.routeBased',
    ),
    [TICKET_NUMBER_TYPE.HYBRID]: translation(
      'register.company.ticketNumberingScheme.schemes.hybrid',
    ),
    [TICKET_NUMBER_TYPE.RANDOM_ALPHA_NUMERIC]: translation(
      'register.company.ticketNumberingScheme.schemes.randomAlphaNumeric',
    ),
    [TICKET_NUMBER_TYPE.RANDOM_NUMERIC]: translation(
      'register.company.ticketNumberingScheme.schemes.randomNumber',
    ),
    [TICKET_NUMBER_TYPE.TIME_STAMPED]: translation(
      'register.company.ticketNumberingScheme.schemes.timeStamped',
    ),
  };
};
