import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import logon from './logon/reducer';
import register from './register/reducer';
import company from './company/reducer';
import discount from './discount/reducer';
import global from './global/reducer';
import user from './user/reducer';
import rhfData from './hook-form/reducer';
import coreui from './coreui/reducer';
import vehicle from './vehicle/reducer';
import gtfs from './gtfs/reducer';
import servicePlan from './plan/reducer';
import inventory from './inventory/reducer';
import invoice from './invoice/reducer';
import realtime from './realtime/reducer';
import applications from './applications/reducer';
import business from './business/reducer';

export default combineReducers({
  gtfs,
  company,
  discount,
  vehicle,
  user,
  logon,
  register,
  global,
  form: formReducer,
  reactHookForm: rhfData,
  coreui,
  servicePlan,
  inventory,
  invoice,
  realtime,
  applications,
  business,
});
