export const SET_API_ERROR = 'inventory/SET_API_ERROR';
export const CLEAR_API_ERROR = 'inventory/CLEAR_API_ERROR';

export const SAVE_SIMCARD_RECHARGE = 'inventory/SAVE_SIMCARD_RECHARGE';
export const SAVE_SIMCARDS = 'inventory/SAVE_SIMCARDS';

export const SAVE_INVENTORY = 'inventory/SAVE_INVENTORY';
export const SET_LOADING = 'inventory/SET_LOADING';
export const SAVE_INVENTORY_USAGE_HISTORY =
  'inventory/SAVE_INVENTORY_USAGE_HISTORY';
