import retry from 'async-retry';
import merge from 'lodash/merge';

import persistedStore from '../../state/store';

import {
  IResponseValidationInnerError,
  IError,
  IResponseError,
} from '../../types/errors';

import { config } from '../../config';
import { IState } from '../../types/state';
import { CURRENCY } from '../../constants/currencies';

const store = persistedStore.store;

export const retries = 5;
const { REACT_APP_TOKEN_API_ID } = config;

export class ResponseError extends Error {
  public message: string = '';

  public code?: string;

  public errorMessage?: string;

  public status?: number;

  public errorCode?: string;

  public error?: IError | IResponseValidationInnerError[];

  constructor(response: IResponseError) {
    super(response.message || response.errorMessage);

    Object.assign(this, response);
  }
}

export const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-authentication-context': 'admin-web',
    'x-api-token': REACT_APP_TOKEN_API_ID,
    'Content-Language': 'en',
    'Content-Currency': CURRENCY.TZS,
    'Access-Control-Allow-Origin': '*',
    'x-gtfs-url': config.REACT_APP_GTFS_API_URL,
    Authorization: REACT_APP_TOKEN_API_ID,
  },
  method: 'GET',
};

const isJson = (response: Response): boolean => {
  const contentType = response.headers.get('Content-Type');
  return !!(contentType && contentType.indexOf('application/json') !== -1);
};

export async function request<T = { [key: string]: any }>(
  url: string,
  options: RequestInit = {},
  fetchFn: typeof fetch = fetch,
): Promise<T> {
  try {
    const state: IState = await store.getState();

    const token = `${state.user?.token}`;
    const language = `${state.global.language?.languageTag}`;

    defaultOptions.headers['Content-Language'] = language;
    // Set preferred currency
    defaultOptions.headers['Content-Currency'] =
      state?.global?.currency?.code || CURRENCY.TZS;
    defaultOptions.headers['Authorization'] = `Bearer ${token}`;
    defaultOptions.headers['x-gtfs-url'] =
      state.gtfs.apiUrl || config.REACT_APP_GTFS_API_URL;
  } catch (error) {
    console.error(error);
  }

  const allOptions = merge({}, defaultOptions, options);

  const response = await fetchFn(url, allOptions);
  const json = isJson(response) ? await response.json() : null;

  if (response.ok) return json;

  throw new ResponseError(json);
}

async function requestRetry<T = { [key: string]: any }>(
  url: string,
  options: RequestInit = {},
  fetchFn: typeof fetch = fetch,
): Promise<T> {
  const response = await retry(
    async (bail: Function) => {
      try {
        return await request<T>(url, options, fetchFn);
      } catch (e) {
        if (e.status >= 400 || e.status < 500) return bail(e as IResponseError);

        throw e;
      }
    },
    {
      retries,
    },
  );

  return response;
}

export default requestRetry;
