import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import requestRetry, { ResponseError } from '../../lib/request';

// TypeScript Types
import {
  IRoute,
  IStop,
  IGeoRouteShape,
  ITripMini,
  IPreviewOptions,
  IRoutePreview,
  IStopTimes,
} from './types';
import { IFares, IStopMini, IFareRules } from '../../types/gtfs';
import { IState } from '../../types/state';

// Global actions
import {
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOADING,
  SAVE_GTFS_DATA,
  SAVE_GTFS_ROUTES_DATA,
  SET_GTFS_LOAD_STATUS,
  SAVE_GTFS_SHAPE_DATA,
  SAVE_GTFS_STOP_DATA,
  SAVE_GTFS_TICKET_FARES,
  SAVE_GTFS_CITY_STOPS,
  SAVE_ALL_GTFS_STOPS,
  SET_API_URL,
  ROUTE_PREVIEW,
  SAVE_GTFS_STOPS,
} from './action-types';

import {
  GTFS_ROUTES,
  GTFS_SHAPE,
  GTFS_TRIP,
  GTFS_FARE,
  GTFS_STOPS_BY_ROUTE_ID,
  TRIP_PREVIEW,
  GET_STOPS,
  STOP_TIME,
} from '../../constants/routes';
import { config } from '../../config';
import { ALL_GTFS_STOPS } from '../../constants/routes';

const { REACT_APP_GTFS_API_URL } = config;

export const setApiError = createAction(SET_API_ERROR);
export const clearApiError = createAction(CLEAR_API_ERROR);
export const setLoading = createAction(SET_LOADING);
export const setGTFSLoadStatus = createAction(SET_GTFS_LOAD_STATUS);
export const setApiUrl = createAction(SET_API_URL);

export const saveGTFSData = createAction(SAVE_GTFS_DATA);

export const saveAllGTFSRoutes = createAction(SAVE_GTFS_ROUTES_DATA);

export const saveGTFSShapeData = createAction(SAVE_GTFS_SHAPE_DATA);

export const saveGTFSStopsData = createAction(SAVE_GTFS_STOP_DATA);

export const saveGTFSTicketFare = createAction(SAVE_GTFS_TICKET_FARES);
export const saveRoutePreview = createAction(ROUTE_PREVIEW);
export const saveAllStops = createAction(SAVE_GTFS_STOPS);

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const saveCityStops = createAction(SAVE_GTFS_CITY_STOPS);

export const saveAllGTFSStops = createAction(SAVE_ALL_GTFS_STOPS);

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can fetch all GTFS Route data
 *
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IRoute | ResponseError} GTFS - All Routes GTFS Data
 */
export const getGTFSAllRoutesData =
  (requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IRoute[] | ResponseError> => {
    try {
      // Get GTFS URL from Redux Store
      const { apiUrl } = (getState() as IState).gtfs;

      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      // Fetch All Routes GTFS Data
      const response = await requestFn<IRoute[]>(`${apiUrl}${GTFS_ROUTES}`);

      // Dispatch Redux Action to save the user to Redux Store
      dispatch(saveAllGTFSRoutes(response));

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (error) {
      dispatch(setApiError(error as ResponseError));
      dispatch(setLoading(false));

      // Set Status to false
      dispatch(setGTFSLoadStatus(false));

      // Return the error from the API
      return error as ResponseError;
    }
  };

/**
 *  A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can fetch shape per route
 *
 * @param object {routeId: string, geo: boolean} geo returns the data in a geo format
 * @param requestFn
 */
export const getShapeDataPerRoute =
  ({ routeId }: { routeId: string }, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IGeoRouteShape | ResponseError> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      // Fetch GeoShapes GTFS Data
      const response = await requestFn<IGeoRouteShape>(
        `${apiUrl}${GTFS_SHAPE}${routeId}&geo=true`,
      );

      // save response
      dispatch(saveGTFSShapeData({ [routeId]: response }));

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (error) {
      dispatch(setApiError(error as ResponseError));
      dispatch(setLoading(false));

      // Set Status to false
      dispatch(setGTFSLoadStatus(false));

      // Return the error from the API
      return error as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can fetch stops per route
 *
 *
 * @param object {routeId: string}
 * @param requestFn request function
 */
export const getStopsByRoute =
  ({ routeId }: { routeId: string }, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IStop[] | ResponseError> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      // Fetch stops GTFS Data
      const response = await requestFn<IStop[]>(
        `${apiUrl}${GTFS_STOPS_BY_ROUTE_ID}${routeId}`,
      );

      // save response
      dispatch(saveGTFSStopsData({ [routeId]: response }));
      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (error) {
      dispatch(setApiError(error as ResponseError));
      dispatch(setLoading(false));

      // Set Status to false
      dispatch(setGTFSLoadStatus(false));

      // Return the error from the API
      return error as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can fetch trips on a route
 *
 * @param routeId - Route Id to fetch all trips on
 * @param requestFn - (Optional) fetcher function
 *
 * @return GTFS - trips
 */
export const getTripsOnRoute =
  (routeId: string, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<ITripMini | ResponseError> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      const response = await requestFn<ITripMini>(
        `${apiUrl}${GTFS_TRIP}?route_id=${routeId}`,
      );

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET so that it can fetch GTFS fare_attributes on a route
 *
 * @param routeId - Route Id to fetch ticket fare
 * @param requestFn - (Optional) fetcher function
 *
 * @return GTFS - Ticket fares for given route
 */
export const getRouteTicketFare =
  (routeId: string, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IFareRules[] | undefined> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      const response = await requestFn<IFareRules[]>(
        `${apiUrl}${GTFS_FARE}?route_id=${routeId}`,
      );

      dispatch(saveGTFSTicketFare(response[0]));

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return undefined;
    }
  };

export const previewTrip =
  ({ time, tripId }: IPreviewOptions, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IRoutePreview | undefined> => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());
      const response = await requestFn<IRoutePreview>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${TRIP_PREVIEW}tripId=${tripId}&departureTime=${time}`,
      );

      await dispatch(saveRoutePreview(response) as any);

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return undefined;
    }
  };

export const getAllGTFSStops =
  (requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IStopMini[] | undefined> => {
    try {
      const { apiUrl = REACT_APP_GTFS_API_URL } = (getState() as IState).gtfs;

      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      const response = await requestFn<IStopMini[]>(
        `${apiUrl}${GET_STOPS}?mini=true`,
      );

      dispatch(saveAllGTFSStops(response));

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return undefined;
    }
  };

export const getStopTimes =
  ({ tripId }: { tripId: string }, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IStopTimes[] | undefined> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;

      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      const response = await requestFn<IStopTimes[]>(
        `${apiUrl}${STOP_TIME}?trip_id=${tripId}`,
      );

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return undefined;
    }
  };

export const getAllStops =
  (requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<IStop[] | undefined> => {
    try {
      const { apiUrl } = (getState() as IState).gtfs;

      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      // clear API Errors
      dispatch(clearApiError());

      const response = await requestFn<IStop[]>(
        `${apiUrl}${ALL_GTFS_STOPS}?mini=true`,
      );

      dispatch(saveAllStops(response));
      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return undefined;
    }
  };
