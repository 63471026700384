import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import requestRetry from '../../lib/request';
import { IDiscount } from '../../types/discount';
import { IResponseError } from '../../types/errors';

import * as action from './action-types';

import * as routes from '../../constants/routes';

import { config } from '../../config';

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const setApiError = createAction(action.SET_API_ERROR);
export const clearApiError = createAction(action.CLEAR_API_ERROR);

export const setLoading = createAction(action.SET_LOADING);
export const saveDiscounts = createAction(action.SAVE_DISCOUNT);

export const setDiscountToUpdate = createAction(action.SET_DISCOUNT_TO_UPDATE);

export const resetDiscountState = createAction(action.RESET_STATE);

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET get all the active
 * discounts owned by a company
 *
 * @param tinNumber The TIN Number of the company who's discounts are being loaded
 * @param requestFn Request function
 */
export const getAllDiscounts =
  ({ tinNumber }: { tinNumber: string }, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IDiscount[]> => {
    try {
      // clear api error
      dispatch(clearApiError());

      // starting loading
      dispatch(setLoading(true));

      const companyDiscounts = await requestFn<IDiscount[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ALL_COMPANY_DISCOUNTS}?tinNumber=${tinNumber}`,
      );

      dispatch(saveDiscounts(companyDiscounts));

      dispatch(setLoading(false));

      return companyDiscounts;
    } catch (error) {
      dispatch(setApiError(error));
      dispatch(setLoading(false));

      return error;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send an HTTP GET to select all discounts owned by a company within a specified date range
 *
 * @description If both start date and end date are supplied the function returns all discounts valid between that period
 *
 * If only start date is supplied the function returns all discounts valid from that date to eternity with end date in the far future
 *
 * If only the end date is supplied the function returns all discounts previously expired or that expires on this date
 *
 * @param Object An object - tinNumber, startDate, endDate as properties
 * @param requestFn Request Function
 *
 * @example {tinNumber: "87986857", startDate: "2020-09-10", endDate: "2020-10-10"}
 */
export const getDiscountsBetween =
  (
    {
      tinNumber,
      startDate,
      endDate,
    }: { tinNumber: string; startDate?: string; endDate?: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IResponseError | IDiscount[]> => {
    try {
      // clear api error
      dispatch(clearApiError());

      // starting loading
      dispatch(setLoading(true));

      const companyDiscounts = await requestFn<IDiscount[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${
          routes.ALL_COMPANY_DISCOUNTS
        }?tinNumber=${tinNumber}${startDate ? '&startDate=' + startDate : ''}${
          endDate ? '&endDate=' + endDate : ''
        }`,
      );

      dispatch(saveDiscounts(companyDiscounts));

      dispatch(setLoading(false));

      return companyDiscounts;
    } catch (error) {
      dispatch(setApiError(error));
      dispatch(setLoading(false));

      return error;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will in turn send an HTTP POST to create a new discount
 *
 *
 * @param values An object with all the required properties of a discount - require properties: {code, amount, tinNumber, startDate, endDate}
 * @param requestFn Request Function
 */
export const createDiscount =
  (values: IDiscount, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IDiscount> => {
    try {
      // clear api error
      dispatch(clearApiError());

      // start loading
      dispatch(setLoading(true));

      const response = await requestFn<IDiscount>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.COMPANY_DISCOUNT}`,
        {
          method: 'POST',
          body: JSON.stringify(values),
        },
      );

      await dispatch(getAllDiscounts({ tinNumber: values?.tinNumber }) as any);

      return response;
    } catch (error) {
      dispatch(setApiError(error));
      dispatch(setLoading(false));

      return error;
    }
  };

export const updateDiscount =
  (values: Partial<IDiscount>, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IDiscount> => {
    try {
      // clear api error
      dispatch(clearApiError());

      // start loading
      dispatch(setLoading(true));

      const response = await requestFn<IDiscount>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.COMPANY_DISCOUNT}/${values?.discountUUID}`,
        {
          method: 'PUT',
          body: JSON.stringify(values),
        },
      );

      await dispatch(
        getAllDiscounts({ tinNumber: values?.tinNumber as string }) as any,
      );

      return response;
    } catch (error) {
      dispatch(setApiError(error));
      dispatch(setLoading(false));

      return error;
    }
  };
