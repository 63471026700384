export const SET_API_ERROR = 'gtfs/SET_API_ERROR';
export const CLEAR_API_ERROR = 'gtfs/CLEAR_API_ERROR';
export const SET_LOADING = 'gtfs/SET_LOADING';

export const SAVE_GTFS_DATA = 'gtfs/SAVE_GTFS_DATA';
export const SAVE_GTFS_ROUTES_DATA = 'gtfs/SAVE_GTFS_ROUTES_DATA';

export const SAVE_GTFS_SHAPE_DATA = 'gtfs/SAVE_GTFS_SHAPE_DATA';
export const SAVE_GTFS_STOP_DATA = 'gtfs/SAVE_GTFS_STOP_DATA';

export const SAVE_GTFS_TICKET_FARES = 'gtfs/SAVE_GTFS_TICKET_FARES';

export const SET_GTFS_LOAD_STATUS = 'gtfs/SET_GTFS_LOAD_STATUS';
export const SAVE_GTFS_CITY_STOPS = 'gtfs/SAVE_GTFS_CITY_STOPS';
export const SAVE_ALL_GTFS_STOPS = 'gtfs/SAVE_ALL_STOPS';
export const SET_API_URL = 'gtfs/SET_API_URL';
export const ROUTE_PREVIEW = 'gtfs/ROUTE_PREVIEW';
export const SAVE_GTFS_STOPS = 'gtfs/SAVE_GTFS_STOPS';
