import { handleActions } from 'redux-actions';
import {
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOADING,
  SAVE_INVENTORY,
  SAVE_INVENTORY_USAGE_HISTORY,
  SAVE_SIMCARD_RECHARGE,
  SAVE_SIMCARDS,
} from './action-types';
import { IInventoryDefaultState } from './types';

export const defaultState: IInventoryDefaultState = {
  apiError: undefined,
  loading: false,
  inventory: [],
  simCards: [],
  inventoryUsageHistory: [],
  simCardRecharge: [],
};

// @ts-ignore
export default handleActions(
  {
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false,
    }),
    [CLEAR_API_ERROR]: (state) => ({ ...state, apiError: undefined }),
    [SAVE_INVENTORY]: (state, { payload }) => ({
      ...state,
      inventory: payload,
    }),
    [SAVE_INVENTORY_USAGE_HISTORY]: (state, { payload }) => ({
      ...state,
      inventoryUsageHistory: payload,
    }),
    [SAVE_SIMCARD_RECHARGE]: (state, { payload }) => ({
      ...state,
      simCardRecharge: payload,
    }),
    [SAVE_SIMCARDS]: (state, { payload }) => ({
      ...state,
      simCards: payload,
    }),
  },
  defaultState,
);
