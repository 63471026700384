import { handleActions } from 'redux-actions';

// Global actions
import {
  SET_LOADING,
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SAVE_GTFS_DATA,
  SET_GTFS_LOAD_STATUS,
  SAVE_GTFS_ROUTES_DATA,
  SAVE_GTFS_SHAPE_DATA,
  SAVE_GTFS_STOP_DATA,
  SAVE_GTFS_TICKET_FARES,
  SET_API_URL,
  ROUTE_PREVIEW,
  SAVE_ALL_GTFS_STOPS,
} from './action-types';

// Import TypeScript types
import { IGTFSDefaultState } from './types';
import { GTFS_API } from '../../constants/company';

export const defaultState: IGTFSDefaultState = {
  loading: false,
  isGTFSDataLoaded: false,
  routes: undefined,
  apiUrl: GTFS_API.UPCOUNTRY_CARGO || '',
  shapes: undefined,
  routePreview: { shape: [], stops: [] },
  allStops: [],
};

// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false, // set loading to false after setting the API error
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_GTFS_DATA]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [SAVE_GTFS_ROUTES_DATA]: (state, { payload }) => ({
      ...state,
      routes: payload,
    }),
    [SAVE_GTFS_SHAPE_DATA]: (state, { payload }) => ({
      ...state,
      shapes: { ...state.shapes, ...payload },
    }),
    [SAVE_GTFS_STOP_DATA]: (state, { payload }) => ({
      ...state,
      stops: { ...state.stops, ...payload },
    }),
    [SAVE_GTFS_TICKET_FARES]: (state, { payload }) => ({
      ...state,
      fares: payload,
    }),
    [SET_GTFS_LOAD_STATUS]: (state, { payload = false }) => ({
      ...state,
      isGTFSDataLoaded: payload,
    }),
    [SAVE_ALL_GTFS_STOPS]: (state, { payload }) => ({
      ...state,
      allStops: payload,
    }),
    [SET_API_URL]: (state, { payload }) => ({
      ...state,
      apiUrl: payload,
    }),
    [ROUTE_PREVIEW]: (state, { payload }) => ({
      ...state,
      routePreview: payload,
    }),
  },
  defaultState,
);
