import { handleActions } from 'redux-actions';

import {
  SAVE_LOGON_USER,
  SAVE_TOKEN,
  SAVE_REFRESH_TOKEN,
  SAVE_USER,
  RESET_STATE,
  RESET_LOADING,
  START_USER_UPDATE,
  SAVE_SEARCHED_USERS,
  END_USER_UPDATE,
  SET_API_ERROR,
  CLEAR_API_ERROR,
  USER_LOGOUT_MODAL,
} from './action-types';
import { IUserDefaultState } from './types';

// import user interface
// import { User as IUser } from './user';

export const defaultState: IUserDefaultState = {
  apiError: undefined,
  loading: false,

  logoutModal: false,

  token: undefined,
  refreshToken: undefined,

  searchedUsers: [],

  details: undefined,
};

// TODO: refactor user reducer to remove redundant code
// TODO: RESET_LOADING, START_USER_UPDATE, END_USER_UPDATE to be replaced with SET_LOADING
// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false, // set loading to false after setting the API error
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [START_USER_UPDATE]: (state) => ({
      ...state,
      loading: true,
    }),
    [END_USER_UPDATE]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    // @ts-ignore
    [SAVE_LOGON_USER]: (
      state,
      { payload: { details, token, refreshToken } },
    ) => ({
      ...state,
      details,
      token,
      refreshToken,
    }),
    [SAVE_USER]: (state, { payload }) => ({
      ...state,
      details: payload,
    }),
    [USER_LOGOUT_MODAL]: (state, { payload = false }) => ({
      ...state,
      logoutModal: payload,
    }),
    [RESET_STATE]: (state) => ({
      ...state,
      ...defaultState,
    }),
    [RESET_LOADING]: (state) => ({
      ...state,
      loading: false,
    }),
    [SAVE_TOKEN]: (state, { payload }) => ({
      ...state,
      token: payload,
    }),
    [SAVE_REFRESH_TOKEN]: (state, { payload }) => ({
      ...state,
      refreshToken: payload,
    }),
    [SAVE_SEARCHED_USERS]: (state, { payload }) => ({
      ...state,
      searchedUsers: payload,
    }),
  },
  defaultState,
);
