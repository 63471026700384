import { handleActions } from 'redux-actions';
import { IVehicle, IVehicleDefaultState } from './types';

import {
  SET_API_ERROR,
  SAVE_VEHICLE,
  CLEAR_API_ERROR,
  END_VEHICLE_UPDATE,
  RESET_STATE,
  SET_LOADING,
  START_VEHICLE_UPDATE,
  SET_SELECTED_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  SET_SEAT_GRID_TEMPLATE,
} from './action-types';
import isArray from 'lodash/isArray';

// setup default state
export const defaultState: IVehicleDefaultState = {
  apiError: undefined,
  loading: false,

  vehicles: undefined,

  // selected vehicle by number plate
  selectedVehicle: undefined,
  seatGridTemplates: undefined,
};

//reducer
// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false,
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [START_VEHICLE_UPDATE]: (state) => ({
      ...state,
      loading: true,
    }),
    [SAVE_VEHICLE]: (state, { payload }) => {
      if (isArray(payload)) return { ...state, vehicles: [...payload] };

      return (
        (state.vehicles && {
          ...state,
          vehicles: [...state.vehicles, payload],
        }) || { ...state, vehicles: [payload] }
      );
    },
    [UPDATE_VEHICLE]: (state, { payload }) => {
      // find the index of the vehicle to be updated
      // @ts-ignore
      const index = state.vehicles.findIndex(
        //@ts-ignore
        (element) => element._id === payload._id,
      );

      // update that vehicle
      return {
        ...state,
        vehicles: state.vehicles && [
          ...state.vehicles.slice(0, index),
          payload,
          ...state.vehicles.slice(index + 1),
        ],
      };
    },
    [DELETE_VEHICLE]: (state, { payload }) => {
      // find the index of the vehicle to be updated
      const index =
        state.vehicles &&
        state.vehicles.findIndex(
          // @ts-ignore
          (element) => element._id === payload._id,
        );

      // remove that vehicle
      if (index && index >= 0)
        return {
          ...state,
          vehicles: state.vehicles && [
            ...state.vehicles.slice(0, index),
            ...state.vehicles.slice(index + 1),
          ],
        };

      return state;
    },
    [END_VEHICLE_UPDATE]: (state) => ({
      ...state,
      loading: false,
    }),
    [RESET_STATE]: (state) => ({
      ...state,
      ...defaultState,
    }),
    [SET_LOADING]: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    [SET_SELECTED_VEHICLE]: (state, { payload = undefined }) => {
      const vehicle: IVehicle | undefined =
        state.vehicles &&
        state.vehicles.find((ride: any) => ride.plateNumber === payload);

      return {
        ...state,
        selectedVehicle: vehicle,
      };
    },
    [SET_SEAT_GRID_TEMPLATE]: (state, { payload }) => {
      return {
        ...state,
        seatGridTemplates: payload,
      };
    },
  },
  defaultState,
);
