import { handleActions } from 'redux-actions';
import {
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOADING,
  SAVE_INVOICES,
  SAVE_CUSTOMERS,
} from './action-types';
import { IInvoiceDefaultState } from './types';

export const defaultState: IInvoiceDefaultState = {
  apiError: undefined,
  loading: false,
  invoices: [],
  customers: [],
};

// @ts-ignore
export default handleActions(
  {
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false,
    }),
    [CLEAR_API_ERROR]: (state) => ({ ...state, apiError: undefined }),
    [SAVE_INVOICES]: (state, { payload }) => ({
      ...state,
      invoices: payload,
    }),
    [SAVE_CUSTOMERS]: (state, { payload }) => ({
      ...state,
      customers: payload,
    }),
  },
  defaultState,
);
