import localforage from 'localforage';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';

import { config } from '../config';

import reducer from './reducer';

let composeFn: Function = compose;

const persistConfig = {
  key: 'safiriRoot',
  storage: localforage,
};

if (config.REACT_APP_DEV_MODE) composeFn = composeWithDevTools;

export const getStore = (defaultState = {}) => {
  const middleWares = [thunk];

  // setup redux persist
  const pReducer = persistReducer(persistConfig, reducer);

  let store: any = createStore(
    pReducer,
    defaultState,
    composeFn(applyMiddleware(...middleWares)),
  );

  if (config.REACT_APP_DEV_MODE)
    store = createStore(
      pReducer,
      defaultState,
      composeFn(applyMiddleware(...middleWares)),
    );

  const persistor = persistStore(store);

  return { persistor, store };
};

export default getStore();
