import { handleActions } from 'redux-actions';
import { findCompanyCategory } from '../../utils/company';

import {
  SAVE_COMPANY,
  SAVE_COMPANIES,
  RESET_STATE,
  RESET_LOADING,
  START_COMPANY_UPDATE,
  END_COMPANY_UPDATE,
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SAVE_STAFF,
  SAVE_TRIPS,
  SAVE_MADE_TRIPS,
  SAVE_TICKETS_SOLD,
  SAVE_PERIODICAL_SALES,
  SAVE_PERIODICAL_PARCEL_DELIVERY_SALES,
  SAVE_SENT_PARCELS,
  SAVE_DELIVERED_PARCELS,
  SAVE_EXPENSES,
  SAVE_LEASED_VEHICLES,
  SAVE_DASHBOARD_SUMMARIES,
  SAVE_DASHBOARD_INCOME_SUMMARIES,
  SAVE_WEBSITE_CONFIG,
} from './action-types';

// import user interface
import {
  ICompany,
  ICompanyDefaultState,
  IDashboardIncomeSummaries,
  IDashboardSummaries,
} from './types';

export const defaultState: ICompanyDefaultState = {
  apiError: undefined,
  loading: false,

  details: undefined,

  staff: [],

  trips: [],

  tripsMade: [],

  ticketSold: [],

  sentParcels: [],

  deliveredParcels: [],

  vehiclesLeased: [],

  periodicalSales: [],

  periodicalParcelDeliverySales: [],

  expenses: [],

  dashboardSummaries: {} as IDashboardSummaries,

  dashboardIncomeSummaries: {} as IDashboardIncomeSummaries,

  all: [],

  websiteConfiguration: undefined,
};

// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
      loading: false,
    }),
    [CLEAR_API_ERROR]: (state) => ({ ...state, apiError: undefined }),
    [START_COMPANY_UPDATE]: (state) => ({
      ...state,
      loading: true,
    }),
    [END_COMPANY_UPDATE]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SAVE_COMPANY]: (state, { payload }) => ({
      ...state,
      details: payload,
      companyCategory: payload
        ? findCompanyCategory((payload as unknown as ICompany)?.companyType)
        : state.companyCategory || undefined,
    }),
    [SAVE_COMPANIES]: (state, { payload }) => ({ ...state, all: payload }),
    [RESET_STATE]: (state) => ({ ...state, ...defaultState }),
    [RESET_LOADING]: (state) => ({ ...state, loading: false }),
    [SAVE_STAFF]: (state, { payload }) => ({ ...state, staff: payload }),
    [SAVE_TRIPS]: (state, { payload }) => ({ ...state, trips: payload }),
    [SAVE_MADE_TRIPS]: (state, { payload }) => ({
      ...state,
      tripsMade: payload,
    }),
    [SAVE_TICKETS_SOLD]: (state, { payload }) => ({
      ...state,
      ticketSold: payload,
    }),
    [SAVE_PERIODICAL_SALES]: (state, { payload }) => ({
      ...state,
      periodicalSales: payload,
    }),
    [SAVE_PERIODICAL_PARCEL_DELIVERY_SALES]: (state, { payload }) => ({
      ...state,
      periodicalParcelDeliverySales: payload,
    }),
    [SAVE_SENT_PARCELS]: (state, { payload }) => ({
      ...state,
      sentParcels: payload,
    }),
    [SAVE_DELIVERED_PARCELS]: (state, { payload }) => ({
      ...state,
      deliveredParcels: payload,
    }),
    [SAVE_EXPENSES]: (state, { payload }) => ({
      ...state,
      expenses: payload,
    }),
    [SAVE_LEASED_VEHICLES]: (state, { payload }) => ({
      ...state,
      vehiclesLeased: payload,
    }),
    [SAVE_DASHBOARD_SUMMARIES]: (state, { payload }) => ({
      ...state,
      dashboardSummaries: payload,
    }),
    [SAVE_DASHBOARD_INCOME_SUMMARIES]: (state, { payload }) => ({
      ...state,
      dashboardIncomeSummaries: payload,
    }),
    [SAVE_WEBSITE_CONFIG]: (state, { payload }) => ({
      ...state,
      websiteConfiguration: payload,
    }),
  },
  defaultState,
);
