/**
 * Possible permission levels
 *
 * -7: User deactivate/deleted account
 * -1: User account revoked
 * 0: User has unverified basic access
 * 1: User has verified account access
 * 2: User has conductor access
 * 3: User has driver access
 * 4: User has agent access
 * 5: User has operator access
 * 333: User has admin access
 * 666: User has owner access
 * 777: User is sudo access
 */
export const USER_PERMISSION = {
  DEACTIVATED: -7,
  REVOKED: -1,
  UNVERIFIED: 0,
  VERIFIED: 1,
  CONDUCTOR: 2,
  DRIVER: 3,
  AGENT: 4,
  OPERATOR: 5,
  RIDER: 111,
  SAFIRI_AGENT: 222,
  ADMIN: 333,
  OWNER: 666,
  SUDO: 777,
};

export const userRoles = {
  /** this role doesn't exits but its just a placeholder for the frontend. */
  PLACEHOLDER: '-10',
  CONDUCTOR: '2',
  DRIVER: '3',
  AGENT: '4',
  ADMIN: '333',
  OWNER: '666',
  SUDO: '777',
  OPERATOR: '5',
  DEACTIVATED: '-7',
  REVOKED: '-1',
  UNVERIFIED: '0',
  VERIFIED: '1',
  RIDER: '111',
  SAFIRI_AGENT: '222',
};

export const DEACTIVATED_ROLE = 'DEACTIVATED_ROLE';
export const REVOKED_ROLE = 'REVOKED_ROLE';
export const UNVERIFIED_ROLE = 'UNVERIFIED_ROLE';
export const VERIFIED_ROLE = 'VERIFIED_ROLE';
export const CONDUCTOR_ROLE = 'CONDUCTOR_ROLE';
export const DRIVER_ROLE = 'DRIVER_ROLE';
export const AGENT_ROLE = 'AGENT_ROLE';
export const OPERATOR_ROLE = 'OPERATOR_ROLE';
export const ADMIN_ROLE = 'ADMIN_ROLE';
export const OWNER_ROLE = 'OWNER_ROLE';
export const SUDO_ROLE = 'SUDO_ROLE';
export const SAFIRI_AGENT_ROLE = 'SAFIRI_AGENT_ROLE';
export const RIDER_ROLE = 'RIDER_ROLE';

export const DEFAULT_SAFIRI_ROOT_USERS = [
  'abraham@itule.me',
  'pwitule@yahoo.com',
  'wandahson@gmail.com',
];
