export enum CURRENCY {
  TZS = 'TZS',
  ZMW = 'ZMW',
  KES = 'KES',
  UGX = 'UGX',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  MWK = 'MWK',
  CNY = 'CNY',
  NGN = 'NGN',
  AUD = 'AUD',
  ZAR = 'ZAR',
}

export enum AGGREGATOR {
  DPO = 'DPO',
  PAWAPAY = 'PAWAPAY',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum CREDIT_CURRENCY {
  TZS = 'TZS',
  ZMW = 'ZMW',
  MWK = 'MWK',
  KES = 'KES',
  UGX = 'UGX',
}

export const DEFAULT_SUPPORTED_COUNTRIES: {
  [key in CURRENCY]: any;
} = {
  [CURRENCY.TZS]: {
    callingCode: ['255'],
    cca2: 'TZ',
    currency: ['TZS'],
    flag: 'flag-tz',
    name: 'Tanzania',
    region: 'Africa',
    subregion: 'Eastern Africa',
  },
  [CURRENCY.ZMW]: {
    callingCode: ['260'],
    cca2: 'ZM',
    currency: ['ZMW'],
    flag: 'flag-zm',
    name: 'Zambia',
    region: 'Africa',
    subregion: 'Eastern Africa',
  },
  [CURRENCY.KES]: {
    callingCode: ['254'],
    cca2: 'KE',
    currency: ['KES'],
    flag: 'flag-ke',
    name: 'Kenya',
    region: 'Africa',
    subregion: 'Eastern Africa',
  },
  [CURRENCY.UGX]: {
    callingCode: ['256'],
    cca2: 'UG',
    currency: ['UGX'],
    flag: 'flag-ug',
    name: 'Uganda',
    region: 'Africa',
    subregion: 'Eastern Africa',
  },
  [CURRENCY.USD]: {
    callingCode: ['1'],
    cca2: 'US',
    currency: ['USD'],
    flag: 'flag-us',
    name: 'United States',
    region: 'Americas',
    subregion: 'North America',
  },
  // NOTE: Europe is not a country, but we need to have a default country for EUR so we've used UK
  [CURRENCY.EUR]: {
    callingCode: ['44'],
    cca2: 'GB',
    currency: ['GBP'],
    flag: 'flag-gb',
    name: 'United Kingdom',
    region: 'Europe',
    subregion: 'Northern Europe',
  },
  [CURRENCY.GBP]: {
    callingCode: ['44'],
    cca2: 'GB',
    currency: ['GBP'],
    flag: 'flag-gb',
    name: 'United Kingdom',
    region: 'Europe',
    subregion: 'Northern Europe',
  },
  [CURRENCY.MWK]: {
    callingCode: ['265'],
    cca2: 'MW',
    currency: ['MWK'],
    flag: 'flag-mw',
    name: 'Malawi',
    region: 'Africa',
    subregion: 'Eastern Africa',
  },
  [CURRENCY.CNY]: {
    callingCode: ['86'],
    cca2: 'CN',
    currency: ['CNY'],
    flag: 'flag-cn',
    name: 'China',
    region: 'Asia',
    subregion: 'Eastern Asia',
  },
  [CURRENCY.NGN]: {
    callingCode: ['234'],
    cca2: 'NG',
    currency: ['NGN'],
    flag: 'flag-ng',
    name: 'Nigeria',
    region: 'Africa',
    subregion: 'Western Africa',
  },
  [CURRENCY.AUD]: {
    callingCode: ['61'],
    cca2: 'AU',
    currency: ['AUD'],
    flag: 'flag-au',
    name: 'Australia',
    region: 'Oceania',
    subregion: 'Australia and New Zealand',
  },
  [CURRENCY.ZAR]: {
    callingCode: ['27'],
    cca2: 'ZA',
    currency: ['ZAR'],
    flag: 'flag-za',
    name: 'South Africa',
    region: 'Africa',
    subregion: 'Southern Africa',
  },
};
