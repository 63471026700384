import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import { config } from '../../config';

// Global actions
import {
  SET_API_ERROR,
  SET_LOADING,
  SET_LANGUAGE,
  CLEAR_API_ERROR,
  TOGGLE_LANGUAGE,
  CREATE_TOAST,
  CLEAR_TOAST,
  SHOW_MODAL,
  RUN_JOYRIDE_TOUR,
  SET_CLICK_ACTION_STATUS,
  SET_CURRENCY_DATA,
  SET_CURRENCY_RATES,
  SET_SOCKET_CONNECTION_STATUS,
  SET_SAFIRI_CONFIGURATIONS,
  SET_SAFIRI_BUSINESS_CONFIGURATIONS,
} from './action-types';
import { IToaster } from '../../components/toaster/Toaster';
import requestRetry from '../../lib/request';
import { IResponseError } from '../../types/errors';
import * as routes from '../../constants/routes';
import { ICurrencyRate, ISafiriBusinessConfig, ISafiriConfigs } from './types';

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const setApiError = createAction(SET_API_ERROR);
export const clearApiError = createAction(CLEAR_API_ERROR);

export const setLoading = createAction(SET_LOADING);

export const setLanguage = createAction(SET_LANGUAGE);

export const setCurrencyData = createAction(SET_CURRENCY_DATA);

export const setCurrencyRates = createAction(SET_CURRENCY_RATES);

export const switchLanguage = createAction(TOGGLE_LANGUAGE);

export const clearToast = createAction(CLEAR_TOAST);

export const showModal = createAction(SHOW_MODAL);

export const runJoyRideTour = createAction(RUN_JOYRIDE_TOUR);

export const setSafiriConfigurations = createAction(SET_SAFIRI_CONFIGURATIONS);

export const setSafiriBusinessConfigurations = createAction(
  SET_SAFIRI_BUSINESS_CONFIGURATIONS,
);

// toggles whether joyrides click request during a tour has been done
export const setJoyRideClickActionStatus = createAction(
  SET_CLICK_ACTION_STATUS,
);
export const saveSocketConnectionStatus = createAction(
  SET_SOCKET_CONNECTION_STATUS,
);
export const createToast = createAction(
  CREATE_TOAST,
  (toastDetails: IToaster) => {
    return toastDetails;
  },
);

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request fetch all tickets or only all ticket bought by a user
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - A list of all user tickets or API error
 */
export const fetchAllTickets =
  (query: any, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      const queryString = Object.keys(query)
        .map((q, index) => {
          if (index === 0) return `?${q}=${query[q]}`;
          return `&${q}=${query[q]}`;
        })
        .join('');

      // Fetch all tickets
      const response = await requestFn<any>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.ALL_TICKETS}${queryString}`,
      );

      // Dispatch Redux Action to save all tickets to Redux Store
      // dispatch(saveTickets(response));

      // End Loading
      dispatch(setLoading(false));

      // Return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return e as IResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET request fetch trip
 *
 * @param query - Request Query
 * @param requestFn - (Optional) fetcher function
 *
 * @return {any | IResponseError} response - A Trip or API error
 */
export const fetchTrip =
  (query: any, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<any | IResponseError> => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(setLoading(true));

      const queryString = Object.keys(query)
        .map((q, index) => {
          if (index === 0) return `?${q}=${query[q]}`;
          return `&${q}=${query[q]}`;
        })
        .join('');

      // Fetch trip
      const response = await requestFn<any>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.TRIP}${queryString}`,
      );

      // End Loading
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e as IResponseError));
      dispatch(setLoading(false));

      // Return the error from the API
      return e as IResponseError;
    }
  };

export const fetchCurrencyRates =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ICurrencyRate[] | null> => {
    try {
      dispatch(setLoading(true));

      const response = await requestFn<ICurrencyRate[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_CURRENCY}?base=true`,
      );

      dispatch(setCurrencyRates(response));
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return null;
    }
  };

export const fetchSafiriConfigurations =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ISafiriConfigs | null> => {
    try {
      dispatch(setLoading(true));

      const response = await requestFn<ISafiriConfigs>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.SAFIRI_CONFIGURATIONS}`,
      );

      dispatch(setSafiriConfigurations(response));
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return null;
    }
  };

export const updateSafiriConfigs =
  (payload: ISafiriConfigs, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ISafiriConfigs | null> => {
    try {
      dispatch(setLoading(true));

      const response = await requestFn<ISafiriConfigs>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.SAFIRI_CONFIGURATIONS}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
      );

      dispatch(setSafiriConfigurations(response));
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return null;
    }
  };

export const fetchSafiriBusinessConfigurations =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ISafiriBusinessConfig | null> => {
    try {
      dispatch(setLoading(true));

      //TODO: Fix the api url
      const response = await requestFn<ISafiriBusinessConfig>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.SAFIRI_BUSINESS_CONFIGURATIONS}`,
      );

      dispatch(setSafiriBusinessConfigurations(response));
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return null;
    }
  };

export const updateSafiriBusinessConfigurations =
  (payload: ISafiriBusinessConfig, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<ISafiriBusinessConfig | null> => {
    try {
      dispatch(setLoading(true));

      //TODO: Fix the api url
      const response = await requestFn<ISafiriBusinessConfig>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.SAFIRI_BUSINESS_CONFIGURATIONS}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
      );

      dispatch(setSafiriBusinessConfigurations(response));
      dispatch(setLoading(false));

      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(setLoading(false));

      // return the error from the API
      return null;
    }
  };
