export const SET_API_ERROR = 'user/SET_API_ERROR';
export const CLEAR_API_ERROR = 'user/CLEAR_API_ERROR';

export const SAVE_TOKEN = 'user/SAVE_TOKEN';
export const SAVE_REFRESH_TOKEN = 'user/SAVE_REFRESH_TOKEN';

export const SAVE_LOGON_USER = 'user/SAVE_LOGON_USER';
export const USER_LOGOUT_MODAL = 'user/USER_LOGOUT_MODAL';

export const START_USER_UPDATE = 'user/START_USER_UPDATE';
export const SAVE_USER = 'user/SAVE_USER';
export const SAVE_UPDATED_USER = 'user/SAVE_UPDATED_USER';
export const SAVE_USER_COMMIT = 'user/SAVE_USER_COMMIT';
export const END_USER_UPDATE = 'user/END_USER_UPDATE';

export const VERIFY_EMAIL_ROLLBACK = 'user/VERIFY_EMAIL_ROLLBACK';
export const VERIFY_MOBILE_ROLLBACK = 'user/VERIFY_MOBILE_ROLLBACK';

export const RESET_STATE = 'user/RESET_STATE';
export const RESET_LOADING = 'user/RESET_LOADING';

export const UPDATE_PUSH_NOTIFICATIONS = 'user/UPDATE_PUSH_NOTIFICATIONS';
export const UPDATE_SALES_SUBSCRIPTION = 'user/UPDATE_SALES_SUBSCRIPTION';

export const SAVE_SEARCHED_USERS = 'user/SAVE_SEARCHED_USERS';
