import { handleActions } from 'redux-actions';

// register actions
import {
  END_ATTEMPT_REGISTER,
  START_ATTEMPT_REGISTER,
  CLEAR_API_ERROR,
  SET_API_ERROR,
} from './action-types';
import { IRegisterDefaultState } from './types';

import { ResponseError } from '../../lib/request';

export const defaultState: IRegisterDefaultState = {
  loading: false,
  apiError: undefined,
};

// @ts-ignore
export default handleActions(
  {
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload as unknown as ResponseError,
    }),
    [START_ATTEMPT_REGISTER]: (state) => ({
      ...state,
      loading: true,
    }),
    [END_ATTEMPT_REGISTER]: (state) => ({
      ...state,
      loading: false,
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
  },
  defaultState,
);
